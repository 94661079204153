import React, {useState} from "react";
import person from "../../../assets/svg/person.svg";
import clock from "../../../assets/svg/clock.svg";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import { useLocation, useNavigate } from "react-router-dom";
import { convertMinsToHrsMins } from "../../../lib/helpers/convertMinsToHrsMins";
import { useTranslation } from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { Countdown } from "../../../components/shared/Countdown/Countdown";

import "./ShortTermJobCard.scss";
import {apiCall} from "../../../api/Api";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";

const ShortTermJobCard = (props: any) => {
  const { job, shiftTotal } = props;
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const maxAvailableTime = dayjs(job?.job_start_time).subtract(12, "hour");
  const currentTime = dayjs();
  const diff = maxAvailableTime?.diff(currentTime, "day", true);
  const days = Math.floor(diff);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    const getPaymentSession = () => {
        setIsLoading(true);
      apiCall("GET", `b2b/job-payment/checkout/${job.id}/`)
          .then((res) => {
              const clientSecret = res.data.client_secret;
              setClientSecret(clientSecret); // Set the client secret for the payment
              setShowJobPayment(true); // Show the JobPayment component
                setIsLoading(false);
          })
          .catch((err) => {
                setIsLoading(false);
              console.log(err);
          });
  };

  return (
    <section className="ShortTermJobCard">
        {showJobPayment && clientSecret && (
            <ModalContainer position="top">
                <JobPayment
                    clientSecret={clientSecret}
                    onCloseClick={() => setShowJobPayment(false)}
                />
            </ModalContainer>
        )}
      <Tooltip
        title={`${selectedLanguage === "SQ" ? `Numri i punëtorëve të nevojshëm: ${job.seats}` : `Number of workers needed: ${job.seats}`}`}
        placement="top"
      >
        <div className="ShortTermJobCard__header">
          <h2 className="ShortTermJobCard__job-title">
            {selectedLanguage === "SQ"
              ? `(${job.seats}) ${job.job_title.title?.sq}`
              : `(${job.seats}) ${job.job_title.title?.en}`}
          </h2>
          <div className="ShortTermJobCard__job-price">
            {job.pay_rate}&euro;/h
          </div>
        </div>
      </Tooltip>
      <div className="ShortTermJobCard__body">
        <div className="ShortTermJobCard__city">
          {job.organization_unit?.city}
        </div>

          {!job?.is_active ? (
              <div className="LongTermJobCard__pay-button"
                   onClick={() => {
                       getPaymentSession();
                   }}
              >
                  {t("jobs.longTermJobCard.pay")}
              </div>
          ) : job?.status === "available" && job?.job_start_time && job.is_active && (
          <div
            className={cs(
              "ShortTermJobCard__upcoming",
              getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                .days < 0 && "ShortTermJobCard__upcoming--started",
            )}
          >
            {getTimeBetweenDates(new Date(), new Date(job.job_start_time))
              .days >= 0
              ? getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                  .days === 0
                ? t("jobs.shortTermJobCard.upcomingTextOne")
                : getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                      .days === 1
                  ? t("jobs.shortTermJobCard.upcomingTextTwo")
                  : `${t("jobs.shortTermJobCard.upcomingTextThree")} ${getTimeBetweenDates(new Date(), new Date(job.job_start_time)).days} ${t("jobs.shortTermJobCard.upcomingTextFour")}`
              : `${selectedLanguage === "SQ" ? "Puna tashmë ka filluar" : "Job has already started"}`}
          </div>
        )}
      </div>
      {job.shifts.length > 1 && (
        <div className="ShortTermJobCard__shift-card-body-another-shift" />
      )}
      <div className="ShortTermJobCard__shift-card-body">
        <div>
          <div className="ShortTermJobCard__shift-card-start">
            {" "}
            {`${daysOfTheWeek[new Date(job.shifts[0]?.start_time).getDay()]} ${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", { month: "short", day: "numeric" })}`}
            {new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            }) !==
            new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            })
              ? ` - ${daysOfTheWeek[new Date(job.shifts[0]?.end_time).getDay()]} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", { month: "short", day: "numeric" })} `
              : ""}
          </div>

          <div className="NewJobForm__shift-card-end">
            {`${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} ${t("jobs.shortTermJobCard.to")} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} `}
          </div>
        </div>
        {shiftTotal && (
          <div className="ShortTermJobCard__shift-card-total">
            {" "}
            {convertMinsToHrsMins(
              getTimeBetweenDates(
                new Date(job.shifts[0]?.start_time),
                new Date(job.shifts[0]?.end_time),
              ).minutes,
            )}{" "}
            total
          </div>
        )}
      </div>
      <div className="ShortTermJobCard__applicants-wrapper">
        <div className="ShortTermJobCard__applicants">
          <img
            src={person}
            alt="person icon"
            className="ShortTermJobCard__person-img"
          />
          {job.status === "assigned" && job.applications_count === 0 && (
            <p> {t("jobs.shortTermJobCard.workers.textOne")}</p>
          )}
          {job.status === "assigned" && job.applications_count !== 0 && (
            <p>
              {job.applications_count}{" "}
              {t("jobs.shortTermJobCard.workers.textTwo")}
            </p>
          )}
          {job.status === "available" && job.applications_count === 0 && (
            <p> {t("jobs.shortTermJobCard.applicants.textOne")}</p>
          )}
          {job.status === "available" && job.applications_count !== 0 && (
            <p className="ShortTermJobCard__applicants-text">
              {job.applications_count}{" "}
              {t("jobs.shortTermJobCard.applicants.textTwo")}
            </p>
          )}
        </div>

        {job.status === "available" && job.applications_count !== 0 && (
          <Tooltip
            title={
              days < 0
                ? `${selectedLanguage === "SQ" ? "Nuk mund të pranoni asnjë kandidat për këtë vend pune" : "You cannot accept any candidates for this job"}`
                : `${selectedLanguage === "SQ" ? "Ju keni edhe kaq kohë për të pranuar kandidatët për këtë punë" : "You still have this much time to accept candidates for this job"}`
            }
            placement="top"
          >
            <div className="ShortTermJobCard__available-job-hours-count">
              <img
                src={clock}
                alt="clock icon"
                className="ShortTermJobCard__clock-icon"
              />
              <span>
                <Countdown endTime={maxAvailableTime} />
              </span>
            </div>
          </Tooltip>
        )}
      </div>
      {job.status === "available" && (
        <Button
          onClick={() => {
            navigate("/jobs/edit-short-term-job");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }}
          className="ShortTermJobCard__edit-button"
          label={t("jobs.shortTermJobCard.edit")}
        />
      )}
      <Button
        onClick={() => {
          if (job.status === "available") {
            navigate("/jobs/short-term-applicants");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          } else {
            navigate("/jobs/short-term-attendance");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }
        }}
        className={cs("ShortTermJobCard__show-btn")}
        label={t(
          job.status === "available"
            ? "jobs.shortTermJobCard.show"
            : "jobs.shortTermJobCard.showWorkers",
        )}
      />
    </section>
  );
};

export default ShortTermJobCard;
